import { atom, selector, selectorFamily } from 'recoil';
import { HEX_OPACITY } from '../../../utils/constants';
import { getTagGroups, getTags } from './../utils/api';

export const tagsAtom = atom({
	key: 'tags',
	default: selector({
		key: 'tags/default',
		get: async () => {
			return (await getTags()) || [];
		},
	}),
});

export const tagsGroupsAtom = atom({
	key: 'tagsGroups',
	default: selector({
		key: 'tagsGroups/default',
		get: async () => {
			const groups = await getTagGroups();
			return [...groups, { name: 'Other', uid: 'other', tags: [], background_color: '#ffb74d' + HEX_OPACITY[15], text_color: '#ffb74d' }];
		},
	}),
});

export const getTagByUid = selectorFamily({
	key: 'getTagByUid',
	get:
		(uid: string) =>
		({ get }) => {
			const tags = get(tagsAtom);
			return tags.find((tag) => tag.uid === uid);
		},
});

export const getTagsByUid = selectorFamily({
	key: 'getTagsByUid',
	get:
		(uids: string[]) =>
		({ get }) => {
			if (uids === undefined || uids.length === 0) return [];

			const tags = get(tagsAtom);
			return tags.filter((tag) => uids.includes(tag.uid));
		},
});
