/**
 * Moves the focus to the next tabbable element after the current element.
 * @param currentElement The current HTML element
 */

export function moveToNextTabIndexAfterElement(currentElement: HTMLElement | null): void {
	if (!currentElement) return;

	const focusableElements: HTMLElement[] = Array.from(document.querySelectorAll('[tabindex="0"]'));

	const buttonPosition: number = Array.from(document.body.getElementsByTagName('*')).indexOf(currentElement);

	const nextElement: HTMLElement | undefined = focusableElements.find((element) => {
		const elementPosition: number = Array.from(document.body.getElementsByTagName('*')).indexOf(element);
		return elementPosition > buttonPosition;
	});

	if (nextElement) {
		nextElement.focus();
	}
}

/**
 * Moves focus to the next tabbable element after the current element.
 * @param currentElement The current HTML element
 */
export function moveToNextTabIndex(currentElement: HTMLElement): void {
	const focusableSelectors = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
	const focusableElements = Array.from(document.querySelectorAll<HTMLElement>(focusableSelectors));

	const currentElementIndex = focusableElements.indexOf(currentElement);
	const nextElementIndex = (currentElementIndex + 1) % focusableElements.length;
	focusableElements[nextElementIndex]?.focus();
}

/**
 * Finds the next tab index relative to the current element.
 * @param currentElement The current HTML element
 * @returns The index of the next tabbable element
 */
export function findNextTabIndex(currentElement: HTMLElement): number {
	const focusableSelectors = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
	const focusableElements = Array.from(document.querySelectorAll<HTMLElement>(focusableSelectors));

	const currentElementIndex = focusableElements.indexOf(currentElement);
	return (currentElementIndex + 1) % focusableElements.length;
}

/**
 * Moves focus to the previous tabbable element before the current element.
 * @param currentElement The current HTML element
 */
export function moveToPreviousTabIndex(currentElement: HTMLElement): void {
	const focusableSelectors = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
	const focusableElements = Array.from(document.querySelectorAll<HTMLElement>(focusableSelectors));

	const currentElementIndex = focusableElements.indexOf(currentElement);
	const previousElementIndex = (currentElementIndex - 1 + focusableElements.length) % focusableElements.length;
	focusableElements[previousElementIndex]?.focus();
}

/**
 * Checks if the element is tabbable.
 * @param element The HTML element to check
 * @returns True if the element is tabbable, false otherwise
 */
export function isTabbable(element: HTMLElement): boolean {
	const tabIndex = element.getAttribute('tabindex');
	return tabIndex !== '-1' && element.tabIndex >= 0;
}

/**
 * Focuses the first tabbable element within a container.
 * @param container The container in which to find and focus the first tabbable element
 */
export function focusFirstTabbableInContainer(container: HTMLElement): void {
	const focusableSelectors = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
	const focusableElements = Array.from(container.querySelectorAll<HTMLElement>(focusableSelectors));
	focusableElements[0]?.focus();
}

/**
 * Focuses the last tabbable element within a container.
 * @param container The container in which to find and focus the last tabbable element
 */
export function focusLastTabbableInContainer(container: HTMLElement): void {
	const focusableSelectors = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
	const focusableElements = Array.from(container.querySelectorAll<HTMLElement>(focusableSelectors));
	focusableElements[focusableElements.length - 1]?.focus();
}

/**
 * Moves focus to a specific tab index.
 * @param tabIndex The index of the tabbable element to focus
 */
export function moveToTabIndex(tabIndex: number): void {
	const focusableSelectors = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
	const focusableElements = Array.from(document.querySelectorAll<HTMLElement>(focusableSelectors));
	if (tabIndex >= 0 && tabIndex < focusableElements.length) {
		focusableElements[tabIndex]?.focus();
	}
}

/**
 * Disables tab navigation for all elements within a container.
 * @param container The container within which to disable tab navigation
 */
export function disableTabNavigationInContainer(container: HTMLElement): void {
	const focusableSelectors = 'button, [href], input, select, textarea, [tabindex]';
	const focusableElements = Array.from(container.querySelectorAll<HTMLElement>(focusableSelectors));
	focusableElements.forEach((element) => {
		element.setAttribute('tabindex', '-1');
	});
}

/**
 * Enables tab navigation for all elements within a container.
 * @param container The container within which to enable tab navigation
 */
export function enableTabNavigationInContainer(container: HTMLElement): void {
	const focusableSelectors = 'button, [href], input, select, textarea, [tabindex="-1"]';
	const focusableElements = Array.from(container.querySelectorAll<HTMLElement>(focusableSelectors));
	focusableElements.forEach((element) => {
		element.removeAttribute('tabindex');
	});
}

/**
 * Scrolls the focused element into view when tabbed to.
 */
export function scrollIntoViewOnTab(): void {
	window.addEventListener('keydown', (e) => {
		if (e.key === 'Tab' && document.activeElement instanceof HTMLElement) {
			setTimeout(() => document.activeElement?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 0);
		}
	});
}

/**
 * Wraps tab navigation within a given container.
 * @param container The container in which to wrap tab navigation
 */
export function wrapTabNavigation(container: HTMLElement): void {
	container.addEventListener('keydown', (e) => {
		if (e.key !== 'Tab') return;

		const focusableElements = container.querySelectorAll<HTMLElement>('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
		const firstElement = focusableElements[0];
		const lastElement = focusableElements[focusableElements.length - 1];

		if (e.shiftKey) {
			// Shift + Tab
			if (document.activeElement === firstElement) {
				lastElement.focus();
				e.preventDefault();
			}
		} else {
			// Tab
			if (document.activeElement === lastElement) {
				firstElement.focus();
				e.preventDefault();
			}
		}
	});
}

/**
 * Moves focus to the next input field when the "Enter" key is pressed.
 * @param container The container in which to enable this behavior
 */
export function focusNextInputOnEnter(container: HTMLElement): void {
	container.addEventListener('keydown', (e) => {
		if (e.key === 'Enter' && document.activeElement instanceof HTMLInputElement) {
			e.preventDefault();
			const focusableElements = container.querySelectorAll<HTMLElement>(
				'input, button, [href], select, textarea, [tabindex]:not([tabindex="-1"])'
			);
			const currentElementIndex = Array.from(focusableElements).indexOf(document.activeElement);
			const nextElement = focusableElements[currentElementIndex + 1];
			nextElement?.focus();
		}
	});
}

/**
 * Saves the currently focused element and restores it later.
 */
export function saveAndRestoreFocus(): [() => void, () => void] {
	let savedElement: HTMLElement | null = null;
	return [
		() => {
			savedElement = document.activeElement as HTMLElement;
		},
		() => {
			savedElement?.focus();
		},
	];
}

/**
 * Sets focus on the first element with an error message.
 * @param container The container to search for error elements
 */
export function focusFirstError(container: HTMLElement): void {
	const firstErrorElement = container.querySelector<HTMLElement>('.error, .has-error');
	firstErrorElement?.focus();
}

/**
 * Disables keyboard navigation for a specific element.
 * @param element The element to disable keyboard navigation for
 */
export function disableKeyboardNavigation(element: HTMLElement): void {
	element.setAttribute('tabindex', '-1');
	element.setAttribute('aria-hidden', 'true');
}

/**
 * Enables keyboard navigation for a specific element.
 * @param element The element to enable keyboard navigation for
 */
export function enableKeyboardNavigation(element: HTMLElement): void {
	element.removeAttribute('tabindex');
	element.removeAttribute('aria-hidden');
}

/**
 * Toggles the visibility of an element and manages focus.
 * @param element The element to toggle visibility for
 */
export function toggleVisibility(element: HTMLElement): void {
	const isVisible = element.style.display !== 'none';
	element.style.display = isVisible ? 'none' : 'block';
	if (!isVisible) {
		element.focus();
	}
}

/**
 * Focuses the next sibling element of the current focused element.
 */
export function focusNextSibling(): void {
	const activeElement = document.activeElement as HTMLElement;
	const nextSibling = activeElement.nextElementSibling as HTMLElement;
	nextSibling?.focus();
}

/**
 * Focuses the previous sibling element of the current focused element.
 */
export function focusPreviousSibling(): void {
	const activeElement = document.activeElement as HTMLElement;
	const previousSibling = activeElement.previousElementSibling as HTMLElement;
	previousSibling?.focus();
}
