import { deleteAppData, getAppData, postAppData, putAppData, TAGS_APP } from '../../../utils/api/apps';
import { ITag, ITagGroup } from '../types';

export const getTags = async () => {
	return await getAppData(TAGS_APP);
};

export const getTagGroups = async () => {
	return await getAppData(TAGS_APP, `/groups`);
};

export const getTagsBySources = async (sources: string[]) => {
	return await getAppData(TAGS_APP, `/sources?${sources.join('&')}`);
};

export const createTag = async (tag: ITag): Promise<ITag> => {
	return await postAppData(TAGS_APP, tag, '');
};

export const createTagGroup = async (group: ITagGroup): Promise<ITagGroup> => {
	return await postAppData(TAGS_APP, group, '/groups');
};

export const assignTagToGroup = async (group: string, tags: string[]) => {
	await putAppData(TAGS_APP, { tags }, `/groups/${group}`);
};

export const addTagToSource = async (tag: string, sources: string[]) => {
	return await putAppData(TAGS_APP, { sources }, `/tags/${tag}`);
};

export const unassignTagFromGroup = async (group: string, tag: string) => {
	return await deleteAppData(TAGS_APP, { tags: [tag] }, `/groups/${group}/tags`);
};

export const deleteTagGroup = async (group: string) => {
	return await deleteAppData(TAGS_APP, null, `/groups/${group}`);
};
