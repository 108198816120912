import { Popover } from 'antd';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_TEXT_COLOR } from '../utils/config';

export const Tag = ({
	children,
	background_color,
	text_color,
	className,
	groups,
	hideGroups = false,
	onClick,
}: {
	children: JSX.Element;
	background_color?: string;
	text_color?: string;
	className?: string;
	groups?: any[];
	hideGroups?: boolean;
	onClick?(): void;
}) => {
	className ??= '';

	const tooltipContent = (
		<div className="flex flex-col p-4">
			<p className="text-xs font-medium">Name of tag:</p>
			<p className="text-xs capitalize">{children}</p>
			<p className="mb-1 mt-2 text-xs font-medium">Part of groups:</p>
			<div className="flex gap-2">
				{groups?.length ? (
					groups.map((v) => (
						<Tag hideGroups={true} className="select-none" key={v.uid} {...v}>
							{v.name}
						</Tag>
					))
				) : (
					<p className="text-xs italic text-gray-500">None</p>
				)}
			</div>
		</div>
	);

	const content = (
		<div
			className={`w-fit rounded-md px-2 py-1 text-center text-[12px] capitalize text-gray-600 ${className}`}
			style={{ background: background_color || DEFAULT_BACKGROUND_COLOR, color: text_color || DEFAULT_TEXT_COLOR }}
			onClick={onClick}
		>
			{children}
		</div>
	);

	if (!hideGroups) {
		return (
			<Popover zIndex={500} className="w-fit" overlayStyle={{ pointerEvents: 'none' }} content={tooltipContent}>
				{content}
			</Popover>
		);
	} else return content;
};
